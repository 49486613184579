import * as Sentry from "@sentry/browser";

Sentry.init({
    dsn:"https://4d204d863a1540f7b35068a6a87bc5a2@sentry.lico.nl/39",
    maxBreadcrumbs: 50,
    environment: process.env.NODE_ENV || 'development'
  });

Sentry.setTag("source", "frontend");

if (window.appContext) {
  Sentry.setTag("tenant", window.appContext.tenant);
  Sentry.setUser({
    id: window.appContext.userId,
  })
}

window.Sentry = Sentry; // for sprockets js
export default Sentry;
